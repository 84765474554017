<template>
  <div class="container mx-auto py-20">
    <!-- component -->
    <div class="md:mt-40 sm:mt-0">
      <div class="md:gap-6">
        <div class="md:col-span-1">
          <div class="md:mb-10 px-4 sm:px-0">
            <h3 class="text-3xl font-medium leading-6 text-gray-900">
              {{ t('CUSTOMER PORTAL') }}
            </h3>
            <p
              v-if="!isPosted"
              class="my-4 text-sm text-gray-600 w-1/2 mx-auto"
            ></p>
          </div>
        </div>
        <div
          class="md:w-1/2 text-left m-auto shadow overflow-hidden sm:rounded-md"
        >
          <div class="grid grid-cols-2 px-4 py-5 bg-white sm:p-6">
            <div class="pb-4 text-lg col-span-2">
              <div>
                <h2>{{ t('Card registration completed.') }}</h2>
              </div>
              <div>
                {{
                  t(
                    'Thank you for your application. Please wait for the payment to be completed.'
                  )
                }}
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 text-center sm:px-6">
            <a href="/ja/service/list">{{ t('Back to Service List') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useTranslation } from '@/locales'

export default {
  name: 'PaymentCardCanceled',
  setup(props) {
    const { t } = useTranslation()
    return { t }
  },
  data() {
    return {
      plan: '',
      isPosted: false,
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
    }
  },
  methods: {},
}
</script>

<style scoped lang="postcss">
.grid div {
  @apply mx-2;
}

input[type='radio'] {
  @apply mt-1 py-2 px-3 block border border-gray-300 shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.required {
  @apply ml-2 bg-red-500 font-medium text-gray-50 text-center inline-block px-2 text-sm;
}
</style>
